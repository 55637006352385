import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import vid from '../assets/videos/brainyorange.mp4'
import transparentDark from '../assets/image/details/brainy/BrainyLightMode.webp'
import transparentLight from '../assets/image/details/brainy/BrainyDarkMode.webp'
import mock1 from '../assets/image/details/brainy/mockup_bike.webp'
import mock3 from '../assets/image/details/brainy/Female_hand.webp'
import mock4 from '../assets/image/details/brainy/2phones.png'




const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="d-flex px-sm-5 justify-content-center"
          css={`
            margin-top: 92px;
          `}
        >
          <video
            className="video-size  mt-lg-5"
            style={{ maxWidth: '100%' }}
            muted
            autoPlay
            loop
          >
            <source src={vid} type="video/mp4" className="img-fluid w-100" />
          </video>
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">UI/UX</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  Quick conceptualization of a cross-media note-taking app with synchronized calendar, additional features for organizing and tracking essential documents, and setting reminders.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              {/* <Col lg="4" className="mb-4 mb-lg-3">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Brainy
                </Title>
              </Col> */}
              <Col lg="4" className="mb-4 mb-lg-5">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  2 Days
                </Title>
              </Col>
              {/* <Col lg="4">
                <Button onClick={handleLiveWorkClicked} arrowRight>Live work</Button>
              </Col> */}
            </Row>
          </Container>
        </div>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  Quick sketch of productivity app design in light and dark
                  mode.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  Creating brief ideas, branding and mockups of UI/UX for the
                  App. <br />
                  <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  To speed up the process, I decided to use existing component
                  libraries and customize them instead of starting from scratch.{' '}
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  Starting the process with existing libraries can be a very
                  efficient way to kick off the projects.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-5 mt-5">
                 <img
                  src={mock4}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="12" className="mb-5">
                {/* <img src={twoIphones} alt="" className="img-fluid w-100" /> */}
              </Col>
              <Col xs="12" className="mb-5">
                {/* <img src={transparentDark} alt="" className="img-fluid w-100" /> */}
              </Col>

              <Col xs="12" className="mb-5">
                <img
                  src={transparentLight}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="12" className="mb-5">
                <img
                  src={transparentDark}
                  alt=""
                  className="img-fluid w-100"
                />
              </Col>
              <Col xs="12" className="mb-5 mt-5">
                <img src={mock1} alt="" className="img-fluid w-100" />
              </Col>

              {/* <Col lg="6" className="pl-lg-4">
                <img src={mock3} alt="" className="img-fluid w-100" />
              </Col> */}
            </Row>
          </Container>
        </Section>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  Quick sketch of productivity app design in light and dark
                  mode.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  Creating brief ideas, branding and mockups of UI/UX for the
                  App. <br />
                  <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  To speed up the process, I decided to use existing component
                  libraries and customize them instead of starting from scratch.{' '}
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  Starting the process with existing libraries can be a very
                  efficient way to kick off the projects.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
